<template>
  <v-app-bar id="app-bar"  class="no-print" absolute app color="transparent" tile height="91" >
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="$vuetify.breakpoint.mobile" color="primary" >mdi-menu</v-icon>
      <v-icon v-else-if="drawer" color="primary" > mdi-chevron-left </v-icon>
      <v-icon v-else color="primary" > mdi-chevron-right </v-icon>
    </v-btn>
    <v-toolbar-title>RISORSE DIPARTIMENTALI</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
    <v-btn  @click="guideonline()" class="mr-3" elevation="1" fab small v-bind="attrs"
          v-on="on">
      <v-icon color="primary"> mdi-book-open-page-variant-outline</v-icon>
    </v-btn>
        </template>
    <span>Guida in linea</span>
    </v-tooltip>
        <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
    <v-btn  @click="exit()" class="mr-3" elevation="1" fab small v-bind="attrs"
          v-on="on">
      <v-icon color="primary"> mdi-exit-run </v-icon>
    </v-btn>
            </template>
    <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState(["drawer"]),
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    ...mapActions({
      exit: 'logout',
      guideonline: 'help'
    }),
  },
  created: () => {},
};
</script>

<style scoped>
@media print {
  .no-print {
    display: none;
    padding: 0 !important;
  }
}
</style>